<template>
    <div>
        <div v-if="p_carga" class="text-center carga">
            <v-img
                src="../assets/logo-ine.png"
                max-height="256px"
                :aspect-ratio="16/9"
                contain
            >
            </v-img>
            <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>
        <div v-if="load">
            <v-app-bar
                color="#1E1E1E"
                dark
                app
            >
            <!--app permite fijar la barra de navegacion arriba -->
        
                <v-toolbar-title>Iniciar Sesión</v-toolbar-title>
        
            </v-app-bar>
            <v-container fluid fill-height class="mb-16">
                <v-row>
                    <v-col cols="12">
                        <v-img
                            src="../assets/pets.png"
                            max-height="256px"
                            :aspect-ratio="1"
                            contain
                        >
                        </v-img>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-5">
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="username"
                            name="username"
                            label="Nombre de Usuario"
                            type="text"
                            prepend-inner-icon="mdi-account"
                            outlined
                            dense
                            solo
                            rounded
                            color="cyan darken-3"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="password"
                            name="password"
                            label="Contraseña"
                            :type="showPassword ? 'text' : 'password'"
                            prepend-inner-icon="mdi-lock"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            v-on:keyup.enter="login()"
                            outlined
                            dense
                            solo
                            rounded
                            color="cyan darken-3"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-btn dark class="botones" @click="login()" key="Enter"><v-icon class="mr-2">mdi-login</v-icon> Ingresar</v-btn>
                </v-row>
                <FlashMessage></FlashMessage>
                <v-footer padless absolute>
                    <v-card
                        flat
                        tile
                        dark
                        width="100%"
                        class="text-center"
                    >
                        <v-card-text>
                            <span class="font-weight white--text text-center"><v-icon>mdi-copyright</v-icon> Copyright {{ new Date().getFullYear() }} - Ine4c Electronics</span>
                        </v-card-text>
                    </v-card>
                </v-footer>
            </v-container>
        </div>
    </div>
</template>
<script>
import axios from "axios"
import md5 from "blueimp-md5"
export default {
    data: () => ({
        showPassword: false,
        opcion: '1',
        username: '',
        password: '',
        load: false,
        p_carga: false,
        token: localStorage.getItem('token')
    }),
    mounted(){
        this.p_carga = true
        setTimeout(() => {
            if(!this.token){
                this.p_carga = false
                this.load = true
            }else{
                this.$router.push('home');
            }
        }, 2000);
    },
    methods: {
        login(){
            //this.flashMessage.error({message: {"opcion": this.opcion, "username": this.username, "password": md5(this.password)}});
            axios.post(this.$pathAPI, {"opcion": this.opcion, "username": this.username, "password": md5(this.password)})
                .then(response => {
                    if(response.status == 200){
                        let datos = response.data
                        if(datos.token != null && datos.mensaje == "Ok"){
                            this.flashMessage.success({message: 'Ingresando...', x: 0, y: 70, time: 1000});
                            localStorage.setItem("token", response.data.token)
                            localStorage.setItem("username", this.username)
                            window.location.reload();
                            this.$router.push('home');
                        }else{
                            this.flashMessage.error({message: 'Usuario y/o contraseña incorrectos!', x: 0, y: 70, time: 1000});
                        }
                    }
                })
                .catch( (e) => {
                    console.log(e)
                    this.flashMessage.error({message: 'Sin conexión a internet!', x: 0, y: 70, time: 1000});
                })
        },
        validUser(){
            let data = {
                'opcion': '7',
                'username': localStorage.getItem('username'),
                'jwt_token': localStorage.getItem('token')
            }
            axios.post(this.$pathAPI, data)
            .then((response) => {
                if(response.status == 200){
                    let datos = response.data
                    if(datos["mensaje"] == "Usuario no autorizado"){
                        window.location.reload();
                        this.$router.push('login');
                    }else{
                        this.load = true
                    }
                }
            })
            .catch( (e) => {
                console.log(e)
                this.flashMessage.error({message: 'Error en la red!', x: 0, y: 70, time: 1000});
            })
        }
    }
}
</script>
<style scoped>
    .carga {
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        text-align: center;
    }
    .v-app-bar, .v-card{
        background: #1488CC;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to top, #004e92, #004e92);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #004e92, #004e92); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    .botones{
        background: #005C97;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #363795, #005C97);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #363795, #005C97); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
</style>